main {
    background-color: #f5f5f6;
}

.main {

    height: 100%;
}
.main .logo img {
    display:        inline-block;
    position:       relative;
    height:          52px;
    width:          228px;
    user-select:    none;
    top:            7px;
    left:           56px;
}

.menu-top-item{
    font-size: 15px !important;
}
.menu-top-item svg{
    color: #606060;
}
.menu-top-item span{
    color: #606060;
}
.main .menu-link .sub-menu{
    padding-left: 15px;
}
.main .menu-link a{
    color: inherit;
    text-decoration: none;
}
.main .menu-link i {
    margin-left: 6px;
    font-size: 24px;
    color: #606060;
}
.main .menu-link svg {
    margin-left: 3px;
    font-size: 30px;
    color: #606060;
}
.main .menu-link.selected{
    background-color: #d7e5d8;
}
.main .menu-link.selected i{
    color: #509556;
}
.main .menu-link.selected svg{
    color: #509556;
}
.main .logged-user-name{
    color:          white;
    margin-right:   12px;
    margin-left:    10px;
    font-family:    "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight:    400;
    line-height:    1.43;
    letter-spacing: 0.01071em;
    font-size:      17px;
    overflow:       hidden;
    white-space:    pre;
    text-overflow:  ellipsis;
    min-width:      70px;
}
.main .logged-user-container {
    border: 1px solid rgba( 255, 255, 255, 0.5 );
    border-radius: 7px;
    padding: 6px;
}
.main .menu-toggle{
    margin-left: -4px;
}
.main .menu-toggle button{
    padding: 8px !important;
}
.main .menu-toggle-labels{
    margin-left: 6px;
}
.main .menu-toggle-ico{
    color: #606060;
    font-size: 16px;
}
.main .Mui-selected .menu-toggle-ico{
    color: #519657;
}
.main .container-scroll{
    height:     100%;
    overflow-y: auto;
}
.main .container .powered-by{
    position: absolute;
    bottom: 7px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}
.main .container-scroll .powered-by{
    width: 100%;
    padding-top: 10px;
    text-align: center;
    margin-bottom: 6px;
}
.main .menu-expand{
    width: 100%;
}
.main .menu-expand .menu-expand-content{

}

.menu-settings .menu-icon-container{
    position: relative;
    height: 22px;
}

.menu-settings .icon{
    margin: 0 4px 0 -4px;
}

.menu-settings .portfolio-icon{
    height: 0.9em
}

.menu-settings .sub-icon{
    width: 1rem !important;
    height: 1rem !important;
    position: absolute;
    top: -2px;
    left: 11px;
}

.menu-settings .sub-icon-cube{
    width: 1rem !important;
    height: 1rem !important;
    position: absolute;
    top: -2px;
    left: 13px;
}

.menu-settings .cube-icon{
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    flex-shrink: 0;
    color: #737373;
    margin-right: 10px;
    margin-left: -3px;
}

/*
.MuiDrawer-root.MuiDrawer-docked {
    box-shadow: 4px 5px 9px -6px rgba(0, 0, 0, 0.75);
}
*/
.MuiDrawer-paperAnchorDockedLeft {
    overflow-x: hidden !important;
}
img.logo {
    width:          30px;
    margin:         0 !important;
    margin-left:    5px !important;
}
.menu-link.logo {
    position:       relative;
    bottom:         0;
}

.layoutTitle {
    margin-left:    10px;
    font-size:      smaller;
    font-weight:    400;
}

.cubeDateTime {
    position:       absolute;
    bottom:         3px;
    left:           26px;
    font-size:      0.7rem;
    color:          rgba(255,255,255,0.7);
}

.toolbar h1 {
    cursor:         default;
}

.licenses-table {
    width: 250px;
}
.licenses-table > div > div {
    display: inline-block;
    height: 30px;
}
.licenses-table > div:nth-of-type(1) div {
    font-weight: bold;
}
.licenses-table > div > div:nth-of-type(1) {
    width: 200px;
}
.licenses-table > div > div:nth-of-type(2) {
    width:  50px;
    text-align: right;
}
.dialog-title-licenses {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.dialog-title-licenses svg {
    margin-right: 10px;
}

.zoom-3, .zoom-3 ~ .chip-wrapper, .zoom-3 ~ .MuiTooltip-popper, .zoom-3 ~ .MuiPopover-root > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { zoom: 0.7; }
.zoom-2, .zoom-2 ~ .chip-wrapper, .zoom-2 ~ .MuiTooltip-popper, .zoom-2 ~ .MuiPopover-root > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { zoom: 0.8; }
.zoom-1, .zoom-1 ~ .chip-wrapper, .zoom-1 ~ .MuiTooltip-popper, .zoom-1 ~ .MuiPopover-root > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { zoom: 0.9; }
.zoom1 , .zoom1  ~ .chip-wrapper, .zoom1  ~ .MuiTooltip-popper, .zoom1  ~ .MuiPopover-root > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { zoom: 1.1; }
.zoom2 , .zoom2  ~ .chip-wrapper, .zoom2  ~ .MuiTooltip-popper, .zoom2  ~ .MuiPopover-root > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { zoom: 1.2; }
.zoom3 , .zoom3  ~ .chip-wrapper, .zoom3  ~ .MuiTooltip-popper, .zoom3  ~ .MuiPopover-root > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { zoom: 1.3; }

/* .simple-menu   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { width: 250px !important; } */
.zoom-3 ~ .simple-menu   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: unset !important; right: 25px !important; }
.zoom-2 ~ .simple-menu   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: unset !important; right: 25px !important; }
.zoom-1 ~ .simple-menu   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: unset !important; right: 25px !important; }
/* .zoom1  ~ .simple-menu   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: 77.25vw    !important; } */
/* .zoom2  ~ .simple-menu   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: 67.5vw !important; } */
/* .zoom3  ~ .simple-menu   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: 60.5vw    !important; } */

.zoom-3 ~ .menu-settings > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: unset !important; right: 167px !important; }
.zoom-2 ~ .menu-settings > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: unset !important; right: 167px !important; }
.zoom-1 ~ .menu-settings > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: unset !important; right: 167px !important; }
/* .zoom1  ~ .menu-settings > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: calc( 91vw  - 256px ) !important; } */
/* .zoom2  ~ .menu-settings > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: calc( 83vw  - 256px ) !important; } */
/* .zoom3  ~ .menu-settings > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { left: calc( 77vw  - 256px ) !important; } */

.zoom-3 ~ .submenu-filters   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom-3 ~ .submenu-export    > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom-3 ~ .submenu-subtotals > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { bottom: 1.2vh !important; top: unset !important; }
.zoom-2 ~ .submenu-filters   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom-2 ~ .submenu-export    > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom-2 ~ .submenu-subtotals > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { bottom: 1.2vh !important; top: unset !important; }
.zoom-1 ~ .submenu-filters   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom-1 ~ .submenu-export    > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom-1 ~ .submenu-subtotals > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { bottom: 1.2vh !important; top: unset !important; }
.zoom3  ~ .submenu-filters   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom3  ~ .submenu-export    > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom3  ~ .submenu-subtotals > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { bottom: 1.2vh !important; top: unset !important; }
.zoom2  ~ .submenu-filters   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom2  ~ .submenu-export    > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom2  ~ .submenu-subtotals > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { bottom: 1.2vh !important; top: unset !important; }
.zoom1  ~ .submenu-filters   > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom1  ~ .submenu-export    > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded,
.zoom1  ~ .submenu-subtotals > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded { bottom: 1.2vh !important; top: unset !important; }

@media (max-width: 900px) {
    .zoom, .full {
        width: 0;
        color: transparent;
    }
}

.toolbar.local { background-color: lightsteelblue;     }
.toolbar.dev   { background-color: lightsalmon;   }
.toolbar.stage { background-color: lightseagreen; }

img.cube {
    height: 34px;
    width:  34px;
}

.menu-top-user .MuiMenuItem-root {
    min-height:     40px;
    padding-top:    0;
    padding-bottom: 0;
}

.use-portfolio > div {
    display:        inline-block !important;
    margin-left:    20px;
}

#root > div.main > div > div > div > div:nth-child(2) > div:nth-child(2) {
    visibility: hidden;
}

.menu-icon-container-notify {
    display: flex;
}